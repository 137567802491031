<messages>["./Login"]</messages>

<template>
  <v-container
    fluid
    class="pa-0">
    <v-row
      justify="center">
      <v-col
        cols="12"
        sm="8"
        md="4">
        <v-card>
          <form
            novalidate
            @submit.prevent="onLogin ()">
            <v-card-title primary-title>
              <div>
                <div
                  class="text-h5"
                  v-text="$t ('totp.headline')"/>
                <div
                  class="cgwng-subheading"
                  v-text="$t ('totp.subtitle-1')"/>
              </div>
            </v-card-title>
            <v-card-text>
              <totp-input-field :v="$v.totpCode"/>
              <v-checkbox v-model="saveTotp" :label="$t('totp.saveTotp')"/>
              <p
                class="cgwng-subheading"
                v-text="$t ('totp.resetText')"/>
              <div class="text-center">
                <v-btn type="submit">
                  <div
                    v-if="!isWorking"
                    v-t="'general.button.ok'"/>
                  <div v-else>
                    <v-progress-circular
                      indeterminate
                      color="primary"/>
                  </div>
                </v-btn>
              </div>
            </v-card-text>
          </form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {mapState, mapMutations, mapActions} from 'vuex'

  import TotpInputField from './components/TotpInputField'
  import totpCodeValidation from './components/TotpInputFieldValidation'

  export default {
    name: 'Totp',

    components: {
      TotpInputField
    },

    data () {
      return {
        isWorking: false,
        totpCode: '',
        saveTotp: false
      }
    },

    validations: {
      totpCode: totpCodeValidation
    },

    computed: {
      ...mapState ({
        desiredRoute: state => state.router.desiredRoute
      })
    },

    methods: {
      ...mapMutations ('router', [
        'resetDesiredRoute'
      ]),

      ...mapActions ({
        redirectAfterLogin: 'router/redirectAfterLogin',
        totpLogin: 'auth/totpLogin'
      }),

      async onLogin () {
        if (!this.$v.$invalid) {
          this.isWorking = true

          await this.totpLogin ({validationCode: this.totpCode, saveTotp: this.saveTotp}) &&
            this.redirectAfterLogin (this.$router)

          this.isWorking = false
        } else {
          this.$v.$touch ()
        }
      }
    }
  }
</script>
