import {required, decimal, between} from 'vuelidate/lib/validators'

const min = 0
const max = 999999

/**
 * validation object for TOTP input field
 */
export default {
  required,
  decimal,
  between: between (min, max)
}

/**
 * validation type message attributes for the validation object
 */
export const messages = {
  required: 'totp.code.required',
  decimal: 'totp.code.decimal',
  between: {key: 'totp.code.between', params: {min, max}}
}
