<messages>["../Login"]</messages>

<template>
  <v-text-field
    v-model.trim="v.$model"
    autofocus
    class="required"
    autocomplete="off"
    name="totpCode"
    type="number"
    :min="minValue"
    :max="maxValue"
    :maxlength="maxLength"
    :label="$t('totp.code.label')"
    :error-messages="validationErrors('', validationMessages)"
    @blur="v.$touch()"/>
</template>

<script>
  import validationMixins from '@/app/core/mixins/ValidationHelper'

  import {messages as validationMessages} from './TotpInputFieldValidation'

  /**
   * count digits in given number
   *
   * @param x {Number}    the number, which digits should be counted
   * @return {number}     number of digits in given number
   */
  function numDigits (x) {
    return Math.max (Math.floor (Math.log10 (Math.abs (x))), 0) + 1
  }

  export default {
    name: 'TotpInputField',

    mixins: [validationMixins],

    props: {
      // the validations sub-tree to associate with this component
      v: {type: Object, required: true}
    },

    data: () => ({
      validationMessages
    }),

    computed: {
      minValue () {
        return this.v.$params.between ? this.v.$params.between.min : null
      },

      maxValue () {
        return this.v.$params.between ? this.v.$params.between.max : null
      },

      maxLength () {
        return this.v.$params.between
          ? numDigits (this.v.$params.between.max)
          : 1
      }
    }
  }
</script>
